import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/Reports/Report.css";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { CUSTOMERS, DRUGS, REPORTFACTOR } from "../../GraphQL/Query";
import { Loader } from "../StaticComponents/Loader";
import { Alert } from "../StaticComponents/Alert";
import {
	Button,
	Input,
	Table,
	Space,
	Tag,
	Select,
	InputNumber,
	Modal,
} from "antd";
import { PlusOutlined, PrinterOutlined } from "@ant-design/icons";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { DateObject } from "react-multi-date-picker";
import { MONTHS } from "../../Constant/const";
const { Option } = Select;
export function FactorReports() {
	const date = new DateObject({
		calendar: persian,
		locale: persian_fa,
		format: "YYYY-MM-DD",
	});
	const today = date.format();
	const client = useApolloClient();
	const navigate = useNavigate();
	const location = useLocation();
	const [factorReport, setFactorReport] = useState(false);
	const [selectedDrugId, setSelectedDrugId] = useState("");
	const [selectedCustomerId, setSelectedCustomerId] = useState("");
	const [searching, setSearching] = useState("");
	const [isLoader, setIsLoader] = useState(false);
	const [factorReports, setFactorReports] = useState([]);
	const [drugs, setDrugs] = useState([]);
	const [customers, setCustomers] = useState([]);
	const [userRole, setUserRole] = useState("");
	const [selectedFactorType, setSelectedFactorType] = useState("");
	const [selectedPaymentType, setSelectedPaymentType] = useState("");
	const [page, setPage] = useState(1);
	if (location.pathname == "/factorReports") {
		location.pathname = "گزارش فاکتورها";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		setUserRole(JSON.parse(activeUser)?.login?.role);
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadDrugs();
		loadCustomers();
	}, []);
	const columns = [
		{
			title: "ردیف",
			key: "index",
			render: (value, item, index) => (page - 1) * 7 + index + 1,
			align: "center",
		},
		{
			title: "نام",
			dataIndex: ["factor", "fullName"],
			render: (fullName, factor) => factor.customer?.fullName,
			key: "fullName",
			filteredValue: [searching],
			onFilter: (value, factor) => {
				return String(factor.customer?.fullName)
					.toLocaleLowerCase()
					.includes(value.toLocaleLowerCase());
			},
			align: "center",
		},
		{
			title: "شماره",
			dataIndex: "factorNumber",
			key: "factorNumber",
			render: (factorNumber, factor) => (
				<Tag
					bordered={false}
					style={{
						fontSize: 15,
						fontFamily: "B-NAZANIN",
					}}>
					{factor.buyFactorNumber || factor.sellFactorNumber}
				</Tag>
			),
			align: "center",
		},
		{
			title: "نوعیت بل",
			dataIndex: "factorType",
			key: "factorType",
			render: (factorType, factor) => (
				<Tag
					bordered={false}
					color={factor.factorType === "Sell" ? "success" : "error"}
					style={{
						fontSize: 15,
						fontFamily: "B-NAZANIN",
					}}>
					{factor.factorType === "Sell" ? "فروش" : "خرید"}
				</Tag>
			),
			align: "center",
		},
		{
			title: "نوعیت پرداخت",
			dataIndex: "paymentType",
			key: "paymentType",
			render: (paymentType, factor) => (
				<Tag
					bordered={false}
					color={factor.paymentType === "Cash" ? "success" : "error"}
					style={{
						fontSize: 15,
						fontFamily: "B-NAZANIN",
					}}>
					{factor.paymentType === "Cash" ? "نقده" : "نسیه"}
				</Tag>
			),
			align: "center",
		},
		{
			title: "مبلغ",
			dataIndex: "amount",
			key: "amount",
			render: (amount, factor) => (
				<Tag
					bordered={false}
					style={{
						fontSize: 15,
						fontFamily: "B-NAZANIN",
					}}>
					{factor.amount}
				</Tag>
			),
			align: "center",
		},
		{
			title: "تخفیف",
			dataIndex: "discount",
			key: "discount",
			render: (discount, factor) => (
				<Tag
					bordered={false}
					style={{
						fontSize: 15,
						fontFamily: "B-NAZANIN",
					}}>
					{factor.discount}
				</Tag>
			),
			align: "center",
		},
		{
			title: "تاریخ",
			dataIndex: "date",
			key: "date",
			render: (date, factor) => factor.date,
			align: "center",
		},
		{
			title: "عملیات",
			dataIndex: "action",
			key: "action",
			render: (record, factor) => {
				return (
					<Space>
						<PrinterOutlined
							onClick={() =>
								navigate("/printFactor", {
									state: { selectedFactor: factor._id },
								})
							}
						/>
					</Space>
				);
			},
			align: "center",
		},
	];
	async function loadDrugs() {
		try {
			const res = await client.query({
				query: DRUGS,
				variables: {},
				fetchPolicy: "no-cache",
			});
			setDrugs([...res.data.getDrugs]);
		} catch (error) {
			console.log(error);
		}
	}
	async function loadCustomers() {
		try {
			const res = await client.query({
				query: CUSTOMERS,
				variables: {},
				fetchPolicy: "no-cache",
			});
			setCustomers([...res.data.getCustomers]);
		} catch (error) {
			console.log(error);
		}
	}
	const handleReportFactor = async (e) => {
		e.preventDefault();
		try {
			const res = await client.query({
				query: REPORTFACTOR,
				variables: {
					factorType: selectedFactorType != "" ? selectedFactorType : undefined,
					paymentType:
						selectedPaymentType != "" ? selectedPaymentType : undefined,
					factorNumber:
						Number(e.target.factorNumber.value) != ""
							? Number(e.target.factorNumber.value)
							: undefined,
					customer: selectedCustomerId != "" ? selectedCustomerId : undefined,
					drug: selectedDrugId != "" ? selectedDrugId : undefined,
					startAmount:
						Number(e.target.startAmount.value) != ""
							? Number(e.target.startAmount.value)
							: undefined,
					endAmount:
						Number(e.target.endAmount.value) != ""
							? Number(e.target.endAmount.value)
							: undefined,
					startDate:
						e.target.startDate.value != "undefined-undefined-undefined"
							? e.target.startDate.value.replace(
									/[\u0660-\u0669\u06f0-\u06f9]/g,
									function (a) {
										return a.charCodeAt(0) & 0xf;
									}
							  )
							: undefined,
					endDate:
						e.target.endDate.value != "undefined-undefined-undefined"
							? e.target.endDate.value.replace(
									/[\u0660-\u0669\u06f0-\u06f9]/g,
									function (a) {
										return a.charCodeAt(0) & 0xf;
									}
							  )
							: undefined,
				},
				fetchPolicy: "no-cache",
			});
			setFactorReports([...res.data.reportFactors]);
		} catch (error) {
			console.log(error);
		}
		setFactorReport(!factorReport);
		setSelectedFactorType();
		setSelectedPaymentType();
		setSelectedCustomerId();
		setSelectedDrugId();
		setSearching("");
	};
	function gotoPrintFactors() {
		navigate("/factorReportPrint", {
			state: { selectedFactorReport: factorReports },
		});
	}
	return (
		<div className="container">
			<Header address={location.pathname} />
			<SideBar />
			<div className="content">
				{isLoader && <Loader />}
				{alert.action != "" && (
					<Alert
						statusImg="success"
						borderColor="success"
						type={alert.type}
						action={alert.action}
						alertStatus={alert.alertStatus}
					/>
				)}
				<div className="file-container">
					<div className="file-header">
						<Input.Search
							placeholder="جستجوی مشتری"
							allowClear
							size="large"
							onChange={(e) => {
								setSearching(e.target.value);
							}}
							style={{
								width: 250,
								direction: "ltr",
								textAlign: "right",
							}}
						/>
						<Button
							type="primary"
							size="large"
							icon={<PlusOutlined />}
							onClick={() => {
								setFactorReport(!factorReport);
							}}
							className="btn">
							گزارش فاکتور
						</Button>
						<Button
							type="primary"
							size="large"
							icon={<PrinterOutlined />}
							onClick={gotoPrintFactors}
							className="btn">
							چاپ
						</Button>
					</div>
					<div className="file-content">
						{factorReport && (
							<Modal
								title="گزارش فاکتور"
								open={factorReport}
								onCancel={() => {
									setFactorReport(!factorReport);
								}}
								footer={null}
								style={{ direction: "rtl" }}
								centered>
								<form onSubmit={handleReportFactor}>
									<Space direction="vertical">
										<Select
											showSearch
											optionFilterProp="children"
											placeholder="نوعیت فاکتور"
											style={{ width: "100%", direction: "rtl" }}
											name="factorType"
											onChange={(value) => {
												setSelectedFactorType(value);
											}}
											size="large">
											<Option
												value={"Buy"}
												key={"Buy"}
												style={{ direction: "rtl" }}>
												خرید
											</Option>
											<Option
												value={"Sell"}
												key={"Sell"}
												style={{ direction: "rtl" }}>
												فروش
											</Option>
										</Select>
										<Select
											showSearch
											optionFilterProp="children"
											placeholder="نوعیت دریافت"
											style={{ width: "100%", direction: "rtl" }}
											name="paymentType"
											onChange={(value) => {
												setSelectedPaymentType(value);
											}}
											size="large">
											<Option
												value={"Cash"}
												key={"Cash"}
												style={{ direction: "rtl" }}>
												نقده
											</Option>
											<Option
												value={"No_Cash"}
												key={"No_Cash"}
												style={{ direction: "rtl" }}>
												نسیه
											</Option>
										</Select>
										<InputNumber
											placeholder="شماره فاکتور"
											id="factorNumber"
											name="factorNumber"
											size="large"
											min={1}
											style={{ width: "100%" }}
										/>
										<Select
											showSearch
											optionFilterProp="children"
											placeholder="نام مشتری"
											style={{ width: "100%", direction: "rtl" }}
											name="customerName"
											onChange={(value) => {
												setSelectedCustomerId(value);
											}}
											size="large">
											{customers.map((customer) => (
												<Option
													key={customer._id}
													value={customer._id}
													style={{ direction: "rtl" }}>
													{customer.fullName +
														" " +
														customer.company +
														" " +
														customer.city}
												</Option>
											))}
										</Select>
										<Select
											showSearch
											optionFilterProp="children"
											placeholder="نام دارو"
											style={{ width: "100%", direction: "rtl" }}
											name="drugName"
											onChange={(value) => {
												setSelectedDrugId(value);
											}}
											size="large">
											{drugs.map((drug, index) => (
												<Option
													key={drug._id}
													value={drug._id}
													style={{ direction: "rtl" }}>
													{drug?.drugType?.title +
														" " +
														drug.name +
														" " +
														drug.company}
												</Option>
											))}
										</Select>
										<Space direction="horizontal">
											<InputNumber
												id="startAmount"
												name="startAmount"
												size="large"
												placeholder="مبلغ فاکتور"
												min={1}
												className="inputRep"
											/>
											<span>تا</span>
											<InputNumber
												id="endAmount"
												name="endAmount"
												size="large"
												placeholder="مبلغ فاکتور"
												min={1}
												className="inputRep"
											/>
										</Space>
										<Space direction="horizontal">
											<DatePicker
												calendar={persian}
												locale={persian_fa}
												months={MONTHS}
												calendarPosition="top-right"
												format="YYYY-MM-DD"
												inputClass="inputDateRep"
												id="startDate"
												name="startDate"
												value={today}
											/>
											<span>تا</span>
											<DatePicker
												calendar={persian}
												locale={persian_fa}
												months={MONTHS}
												calendarPosition="top-right"
												format="YYYY-MM-DD"
												inputClass="inputDateRep"
												id="endDate"
												name="endDate"
												value={today}
											/>
										</Space>
										<Space
											style={{
												width: "100%",
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
												alignItems: "center",
											}}>
											<Button
												type="primary"
												htmlType="submit"
												size="large"
												icon={<PlusOutlined />}
												className="btn">
												گزارش فاکتور
											</Button>
										</Space>
									</Space>
								</form>
							</Modal>
						)}
						<Table
							dataSource={factorReports}
							columns={columns}
							loading={isLoader}
							locale={{
								emptyText: <img src="emptyData.png" alt="empty" width={200} />,
							}}
							pagination={{
								pageSize: 7,
								showSizeChanger: false,
								onChange(current) {
									setPage(current);
								},
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
