import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/Reports/Report.css";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { REPORTCHECK } from "../../GraphQL/Query";
import { CHECKS, CUSTOMERS } from "../../GraphQL/Query";
import {
	Button,
	Input,
	Table,
	Space,
	Tag,
	Select,
	Modal,
	InputNumber,
} from "antd";
import { PlusOutlined, PrinterOutlined } from "@ant-design/icons";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { DateObject } from "react-multi-date-picker";
import { MONTHS } from "../../Constant/const";
const { Option } = Select;
export function CheckReport() {
	const date = new DateObject({
		calendar: persian,
		locale: persian_fa,
		format: "YYYY-MM-DD",
	});
	const today = date.format();
	const client = useApolloClient();
	const location = useLocation();
	const navigate = useNavigate();
	const [checks, setChecks] = useState([]);
	const [reportChecks, setReportChecks] = useState([]);
	const [showDark, setShowDark] = useState(false);
	const [reportCheck, setReportCheck] = useState(false);
	const [searching, setSearching] = useState("");
	const [isLoader, setIsLoader] = useState(false);
	const [customers, setCustomers] = useState([]);
	const [selectedCustomer, setSelectedCustomer] = useState("");
	const [selectedCustomerId, setSelectedCustomerId] = useState("");
	const [selectedType, setSelectedType] = useState("");
	const [userRole, setUserRole] = useState("");
	const [page, setPage] = useState(1);
	if (location.pathname == "/checkReports") {
		location.pathname = "گزارش اسناد";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		setUserRole(JSON.parse(activeUser)?.login?.role);
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadChecks();
		loadCustomers();
	});
	const columns = [
		{
			title: "ردیف",
			key: "index",
			render: (value, item, index) => (page - 1) * 7 + index + 1,
			align: "center",
		},
		{
			title: "نام",
			dataIndex: "fullName",
			filteredValue: [searching],
			onFilter: (value, check) => {
				return String(check.customer.fullName)
					.toLocaleLowerCase()
					.includes(value.toLocaleLowerCase());
			},
			render: (fullName, check) => check.customer.fullName,
			key: "fullName",
			align: "center",
		},
		{
			title: "شماره سند",
			dataIndex: ["checkInNumber", "checkOutNumber"],
			key: "checkNumber",
			render: (checkNumber, check) => (
				<Tag
					bordered={false}
					style={{
						fontSize: 15,
						fontFamily: "B-NAZANIN",
					}}>
					{check.checkInNumber || check.checkOutNumber}
				</Tag>
			),
			align: "center",
		},
		{
			title: "نوعیت",
			dataIndex: "checkType",
			key: "checkType",
			render: (checkType, check) => (
				<Tag
					bordered={false}
					color={check.checkType == "Check_In" ? "success" : "error"}
					style={{
						fontSize: 15,
						fontFamily: "B-NAZANIN",
					}}>
					{check.checkType == "Check_In" ? "دریافتی" : "پرداختی"}
				</Tag>
			),
			align: "center",
		},
		{
			title: "مبلغ",
			dataIndex: "amount",
			key: "amount",
			render: (amount, check) => (
				<Tag
					bordered={false}
					style={{
						fontSize: 15,
						fontFamily: "B-NAZANIN",
					}}>
					{check.amount}
				</Tag>
			),
			align: "center",
		},
		{
			title: "تاریخ",
			dataIndex: "date",
			key: "date",
			render: (date, check) => check.date,
			align: "center",
		},
	];
	const loadChecks = async () => {
		try {
			const res = await client.query({
				query: CHECKS,
				variables: {},
				fetchPolicy: "no-cache",
			});
			setChecks([...res.data.getChecks]);
		} catch (error) {
			console.log(error);
		}
	};
	async function loadCustomers() {
		try {
			const res = await client.query({
				query: CUSTOMERS,
				variables: {},
				fetchPolicy: "no-cache",
			});
			setCustomers([...res.data.getCustomers]);
		} catch (error) {
			console.log(error);
		}
	}
	const handleReportCheck = async (e) => {
		e.preventDefault();
		try {
			const res = await client.query({
				query: REPORTCHECK,
				variables: {
					checkType: selectedType != "" ? selectedType : undefined,
					checkNumber:
						Number(e.target.checkNumber.value) != ""
							? Number(e.target.checkNumber.value)
							: undefined,
					customer: selectedCustomerId != "" ? selectedCustomerId : undefined,
					startAmount:
						Number(e.target.startAmount.value) != ""
							? Number(e.target.startAmount.value)
							: undefined,
					endAmount:
						Number(e.target.endAmount.value) != ""
							? Number(e.target.endAmount.value)
							: undefined,
					startDate:
						e.target.startDate.value != "undefined-undefined-undefined"
							? e.target.startDate.value.replace(
									/[\u0660-\u0669\u06f0-\u06f9]/g,
									function (a) {
										return a.charCodeAt(0) & 0xf;
									}
							  )
							: undefined,
					endDate:
						e.target.endDate.value != "undefined-undefined-undefined"
							? e.target.endDate.value.replace(
									/[\u0660-\u0669\u06f0-\u06f9]/g,
									function (a) {
										return a.charCodeAt(0) & 0xf;
									}
							  )
							: undefined,
				},
				fetchPolicy: "no-cache",
			});
			setReportChecks([...res.data.reportChecks]);
		} catch (error) {
			console.log(error);
		}
		setReportCheck(!reportCheck);
		setSelectedType();
		setSearching("");
		setSelectedCustomerId();
	};
	function gotoPrintChecks() {
		navigate("/checkReportPrint", {
			state: { selectedCheckReport: reportChecks },
		});
	}
	return (
		<div className="container">
			<Header address={location.pathname} />
			<SideBar />
			<div className="content">
				<div className="file-container">
					<div className="file-header">
						<Input.Search
							placeholder="جستجوی مشتری"
							allowClear
							size="large"
							onChange={(e) => {
								setSearching(e.target.value);
							}}
							style={{
								width: 250,
								direction: "ltr",
								textAlign: "right",
							}}
						/>
						<Button
							type="primary"
							size="large"
							icon={<PlusOutlined />}
							onClick={() => {
								setReportCheck(!reportCheck);
								setShowDark(!showDark);
							}}
							className="btn">
							گزارش سند
						</Button>
						<Button
							type="primary"
							size="large"
							icon={<PrinterOutlined />}
							onClick={gotoPrintChecks}
							className="btn">
							چاپ
						</Button>
					</div>
					<div className="file-content">
						{reportCheck && (
							<Modal
								title="گزارش سند"
								open={reportCheck}
								onCancel={() => {
									setReportCheck(!reportCheck);
									setSearching("");
									setSelectedCustomer();
								}}
								footer={null}
								style={{ direction: "rtl" }}
								centered>
								<form onSubmit={handleReportCheck}>
									<Space direction="vertical">
										<Select
											showSearch
											optionFilterProp="children"
											placeholder="نوعیت سند"
											style={{ width: "100%", direction: "rtl" }}
											name="checkType"
											onChange={(value) => {
												setSelectedType(value);
											}}
											size="large">
											<Option
												key={"Check_In"}
												value="Check_In"
												style={{ direction: "rtl" }}>
												دریافتی
											</Option>
											<Option
												key={"Check_Out"}
												value="Check_Out"
												style={{ direction: "rtl" }}>
												پرداختی
											</Option>
										</Select>
										<InputNumber
											id="checkNumber"
											name="checkNumber"
											size="large"
											placeholder="شماره چک"
											min={1}
											style={{
												width: "100%",
											}}
										/>
										<Select
											showSearch
											optionFilterProp="children"
											placeholder="جستجوی مشتری"
											style={{ width: "100%", direction: "rtl" }}
											name="stack"
											onChange={(value) => {
												setSelectedCustomerId(value);
											}}
											size="large">
											{customers.map((customer) => (
												<Option
													key={customer._id}
													value={customer._id}
													style={{ direction: "rtl" }}>
													{customer.fullName +
														" " +
														customer.company +
														" " +
														customer.city}
												</Option>
											))}
										</Select>
										<Space direction="horizontal">
											<InputNumber
												id="startAmount"
												name="startAmount"
												size="large"
												placeholder="مبلغ سند"
												min={1}
												className="inputRep"
											/>
											<span>تا</span>
											<InputNumber
												id="endAmount"
												name="endAmount"
												size="large"
												placeholder="مبلغ سند"
												min={1}
												className="inputRep"
											/>
										</Space>
										<Space direction="horizontal">
											<DatePicker
												calendar={persian}
												locale={persian_fa}
												months={MONTHS}
												calendarPosition="top-right"
												format="YYYY-MM-DD"
												inputClass="inputDateRep"
												id="startDate"
												name="startDate"
												value={today}
											/>
											<span>تا</span>
											<DatePicker
												calendar={persian}
												locale={persian_fa}
												months={MONTHS}
												calendarPosition="top-right"
												format="YYYY-MM-DD"
												inputClass="inputDateRep"
												id="endDate"
												name="endDate"
												value={today}
											/>
										</Space>
										<Space
											style={{
												width: "100%",
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
												alignItems: "center",
											}}>
											<Button
												type="primary"
												htmlType="submit"
												size="large"
												icon={<PlusOutlined />}
												className="btn">
												گزارش سند
											</Button>
										</Space>
									</Space>
								</form>
							</Modal>
						)}
						<Table
							dataSource={reportChecks}
							columns={columns}
							loading={isLoader}
							locale={{
								emptyText: <img src="emptyData.png" alt="empty" width={200} />,
							}}
							pagination={{
								pageSize: 7,
								showSizeChanger: false,
								onChange(current) {
									setPage(current);
								},
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
