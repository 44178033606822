import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/Reports/Report.css";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { REPORTDRUG } from "../../GraphQL/Query";
import { Loader } from "../StaticComponents/Loader";
import { Alert } from "../StaticComponents/Alert";
import { DRUGS, DRUGTYPES, STACKS } from "../../GraphQL/Query";
import {
	Button,
	Input,
	Modal,
	Select,
	Space,
	Table,
	Tag,
	DatePicker,
	InputNumber,
} from "antd";
import { PlusOutlined, PrinterOutlined } from "@ant-design/icons";
const { Option } = Select;
export function DrugReports() {
	const client = useApolloClient();
	const navigate = useNavigate();
	const location = useLocation();
	const [selectedDrug, setSelectedDrug] = useState("");
	const [showDark, setShowDark] = useState(false);
	const [drugReport, setDrugReport] = useState(false);
	const [searching, setSearching] = useState("");
	const [drugs, setDrugs] = useState([]);
	const [drugReports, setDrugReports] = useState([]);
	const [stacks, setStacks] = useState([]);
	const [drugTypes, setDrugTypes] = useState([]);
	const [isLoader, setIsLoader] = useState(false);
	const [selectedDrugType, setSelectedDrugType] = useState("");
	const [selectedDrugId, setSelectedDrugId] = useState("");
	const [selectedStack, setSelectedStack] = useState("");
	const [page, setPage] = useState(1);
	const [alert, setAlert] = useState({
		type: "",
		alertStatus: "",
		action: "",
	});
	if (location.pathname == "/drugReports") {
		location.pathname = "گزارش داروها";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadDrugTypes();
		loadStacks();
		loadDrugs();
	}, []);
	const columns = [
		{
			title: "ردیف",
			key: "index",
			render: (value, item, index) => (page - 1) * 7 + index + 1,
			align: "center",
		},
		{
			title: "نوعیت",
			dataIndex: "drugType",
			key: "drugType",
			render: (drugType) => drugType.title,
			align: "center",
		},
		{
			title: "نام",
			dataIndex: "name",
			filteredValue: [searching],
			onFilter: (value, record) => {
				return String(record.name)
					.toLocaleLowerCase()
					.includes(value.toLocaleLowerCase());
			},
			key: "name",
			align: "center",
		},
		{
			title: "کمپنی",
			dataIndex: "company",
			key: "company",
			align: "center",
		},
		{
			title: "کشور",
			dataIndex: "country",
			key: "country",
			align: "center",
		},
		{
			title: "تعداد",
			dataIndex: "amount",
			key: "amount",
			render: (amount) => (
				<Tag
					bordered={false}
					color={amount > 0 ? "success" : amount === 0 ? "" : "error"}
					style={{
						fontSize: 15,
						fontFamily: "B-NAZANIN",
						direction: "ltr",
					}}>
					{amount}
				</Tag>
			),
			align: "center",
		},
		{
			title: "قیمت",
			dataIndex: "price",
			key: "price",
			render: (price) => (
				<Tag
					bordered={false}
					style={{
						fontSize: 15,
						fontFamily: "B-NAZANIN",
					}}>
					{price}
				</Tag>
			),
			align: "center",
		},
		{
			title: "تاریخ",
			dataIndex: "expDate",
			key: "expDate",
			align: "center",
		},
	];
	function gotoPrintDrug() {
		navigate("/drugReportPrint", {
			state: { selectedDrugReport: drugReports },
		});
	}
	async function loadDrugs() {
		try {
			const res = await client.query({
				query: DRUGS,
				variables: {},
				fetchPolicy: "no-cache",
			});
			setDrugs([...res.data.getDrugs]);
		} catch (error) {
			console.log(error);
		}
	}
	async function loadDrugTypes() {
		try {
			const res = await client.query({
				query: DRUGTYPES,
				variables: {},
				fetchPolicy: "no-cache",
			});
			setDrugTypes([...res.data.getDrugTypes]);
		} catch (error) {
			console.log(error);
		}
	}
	async function loadStacks() {
		try {
			const res = await client.query({
				query: STACKS,
				variables: {},
				fetchPolicy: "no-cache",
			});
			setStacks([...res.data.getStacks]);
		} catch (error) {
			console.log(error);
		}
	}
	const handleReportDrug = async (e) => {
		e.preventDefault();
		try {
			const res = await client.query({
				query: REPORTDRUG,
				variables: {
					drugType: selectedDrugType,
					drugName: selectedDrugId != "" ? selectedDrugId : undefined,
					drugCompany:
						e.target.drugCompany.value != ""
							? e.target.drugCompany.value
							: undefined,
					drugCountry:
						e.target.drugCountry.value != ""
							? e.target.drugCountry.value
							: undefined,
					drugStack: selectedStack != "" ? selectedStack : undefined,
					startAmount:
						Number(e.target.startAmount.value) != ""
							? Number(e.target.startAmount.value)
							: undefined,
					endAmount:
						Number(e.target.endAmount.value) != ""
							? Number(e.target.endAmount.value)
							: undefined,
					startPrice:
						Number(e.target.startPrice.value) != ""
							? Number(e.target.startPrice.value)
							: undefined,
					endPrice:
						Number(e.target.endPrice.value) != ""
							? Number(e.target.endPrice.value)
							: undefined,
					startDate:
						e.target.startDate.value != ""
							? e.target.startDate.value.replace(
									/[\u0660-\u0669\u06f0-\u06f9]/g,
									function (a) {
										return a.charCodeAt(0) & 0xf;
									}
							  )
							: undefined,
					endDate:
						e.target.endDate.value != ""
							? e.target.endDate.value.replace(
									/[\u0660-\u0669\u06f0-\u06f9]/g,
									function (a) {
										return a.charCodeAt(0) & 0xf;
									}
							  )
							: undefined,
				},
				fetchPolicy: "no-cache",
			});
			setDrugReports([...res.data.reportDrugs]);
		} catch (error) {
			console.log(error);
		}
		setDrugReport(!drugReport);
		setSelectedDrug("");
		setSelectedDrugType();
		setSelectedDrugId();
		setSelectedStack();
	};
	return (
		<div className="container">
			<Header address={location.pathname} />
			<SideBar />
			<div className="content">
				{isLoader && <Loader />}
				{alert.action != "" && (
					<Alert
						statusImg="success"
						borderColor="success"
						type={alert.type}
						action={alert.action}
						alertStatus={alert.alertStatus}
					/>
				)}
				<div className="file-container">
					<div className="file-header">
						<Input.Search
							placeholder="جستجوی دارو"
							allowClear
							size="large"
							onChange={(e) => {
								setSearching(e.target.value);
							}}
							style={{
								width: 250,
								direction: "ltr",
								textAlign: "right",
							}}
						/>
						<Button
							type="primary"
							size="large"
							icon={<PlusOutlined />}
							onClick={() => {
								setDrugReport(!drugReport);
								setShowDark(!showDark);
							}}
							className="btn">
							گزارش دارو
						</Button>
						<Button
							type="primary"
							size="large"
							icon={<PrinterOutlined />}
							onClick={gotoPrintDrug}
							className="btn">
							چاپ
						</Button>
					</div>
					<div className="file-content">
						{drugReport && (
							<Modal
								title="گزارش دارو"
								open={drugReport}
								onCancel={() => {
									setDrugReport(!drugReport);
								}}
								footer={null}
								style={{ direction: "rtl" }}
								centered>
								<form onSubmit={handleReportDrug}>
									<Space direction="vertical">
										<Select
											showSearch
											optionFilterProp="children"
											placeholder="نوعیت دارو"
											style={{ width: "100%", direction: "rtl" }}
											name="checkType"
											onChange={(value) => {
												setSelectedDrugType(value);
											}}
											size="large">
											{drugTypes.map((drugType) => {
												return (
													<Option
														value={drugType._id}
														key={drugType._id}
														style={{ direction: "rtl" }}>
														{drugType.title}
													</Option>
												);
											})}
										</Select>
										<Select
											showSearch
											optionFilterProp="children"
											placeholder="نام دارو"
											style={{ width: "100%", direction: "rtl" }}
											name="drugName"
											onChange={(value) => {
												setSelectedDrugId(value);
											}}
											size="large">
											{drugs.map((drug, index) => (
												<Option
													key={drug._id}
													value={drug.name}
													style={{ direction: "rtl" }}>
													{drug.name}
												</Option>
											))}
										</Select>
										<Input
											placeholder="کمپنی دارو"
											id="drugCompany"
											name="drugCompany"
											size="large"
										/>
										<Input
											placeholder="کشور دارو"
											id="drugCountry"
											name="drugCountry"
											size="large"
										/>
										<Select
											showSearch
											optionFilterProp="children"
											placeholder="گدام دارو"
											style={{ width: "100%", direction: "rtl" }}
											name="drugStack"
											onChange={(value) => {
												setSelectedStack(value);
											}}
											size="large">
											{stacks.map((stack) => (
												<Option
													key={stack._id}
													value={stack._id}
													style={{ direction: "rtl" }}>
													{stack.name}
												</Option>
											))}
										</Select>
										<Space direction="horizontal">
											<InputNumber
												id="startAmount"
												name="startAmount"
												size="large"
												placeholder="تعداد دارو"
												min={1}
												className="inputRep"
											/>
											<span>تا</span>
											<InputNumber
												id="endAmount"
												name="endAmount"
												size="large"
												placeholder="تعداد دارو"
												min={1}
												className="inputRep"
											/>
										</Space>
										<Space direction="horizontal">
											<InputNumber
												id="startPrice"
												name="startPrice"
												size="large"
												placeholder="قیمت دارو"
												min={1}
												className="inputRep"
											/>
											<span>تا</span>
											<InputNumber
												id="endPrice"
												name="endPrice"
												size="large"
												placeholder="قیمت دارو"
												min={1}
												className="inputRep"
											/>
										</Space>
										<Space direction="horizontal">
											<DatePicker
												placeholder="تاریخ دارو"
												placement="topRight"
												id="startDate"
												name="startDate"
												size="large"
												className="inputRep"
											/>
											<span>تا</span>
											<DatePicker
												placeholder="تاریخ دارو"
												placement="topRight"
												id="endDate"
												name="endDate"
												size="large"
												className="inputRep"
											/>
										</Space>
										<Space
											style={{
												width: "100%",
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
												alignItems: "center",
											}}>
											<Button
												type="primary"
												htmlType="submit"
												size="large"
												icon={<PlusOutlined />}
												className="btn">
												گزارش دارو
											</Button>
										</Space>
									</Space>
								</form>
							</Modal>
						)}
						<Table
							dataSource={drugReports}
							columns={columns}
							loading={isLoader}
							locale={{
								emptyText: <img src="emptyData.png" alt="empty" width={200} />,
							}}
							pagination={{
								pageSize: 7,
								showSizeChanger: false,
								onChange(current) {
									setPage(current);
								},
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
